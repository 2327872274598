@import './styles/colors.scss';
@import './styles/components.scss';
@import './styles/fonts.scss';
@import './styles/atom.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
   &:active, &:focus, &:visited {
     color: black;
   }
}

code {
  color: $green;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: $bg;

  .privateContent {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;

    .greenBtn-sm {
      background-color: $green;
      padding: 8px 10px 6px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-top: -8px;
      margin-bottom: -10px;

      @media (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 5px;
      }

      > span {
        color: white;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
    }

    .pageContent {
      background: #FFFFFF;
      border-radius: 15px;
      margin-bottom: 20px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-self: stretch;
      margin-right: 30px;
      margin-left: 30px;

      @media (max-width: 767px) {
        margin-right: 15px;
        margin-left: 15px;
      }

      .profile-infoBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid $border;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }

        .profile-personInfo {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          border-right: solid 1px $border;

          @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            border-right: 0;
          }

          .profile-personInfo-preview {
            margin-right: 30px;

            @media (max-width: 767px) {
              margin-right: 15px;
              margin-left: 15px;
              width: 100%;
              padding-bottom: 0;
              padding-right: 0;
            }

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100px;
              height: 100px;
              border-radius: 50px;
              background-color: $grey;

              > span {
                font-size: 40px;
                font-family: 'Raleway';
                color: black;
              }
            }
            > span.link {
              @media (max-width: 767px) {
                margin: 20px auto 20px auto;
                display: block;
              }
            }
          }
          .profile-personInfo-info {
            padding-bottom: 30px;
            padding-right: 30px;
            margin-right: 30px;

            @media (max-width: 767px) {
              padding-bottom: 0;
              padding-right: 0;
              margin-right: 0;
            }
          }
        }

        p {
          color: black;
          font-size: 22px;
          margin-bottom: 20px;
          margin-top: 0;

          &.label {
            color: rgba(0, 0, 0, 0.45);
            font-size: 16px;
            margin-bottom: 10px;

            .link {
              font-weight: 500;
              font-size: 14px;
              display: inline;
              padding-left: 10px;
            }
          }
        }
      }

      .profile-controlBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 30px;
      }

      .userPreviewRoundBig {
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background-color: $grey;

        @media (max-width: 767px) {
          width: 100%;
          margin-top: 15px;
          margin-left: 15px;
          margin-right: 15px;
        }

        > span {
          font-size: 40px;
          font-family: 'Raleway';
          color: black;
        }
      }
      .userInfo {
        margin-top: 30px;
        margin-right: 20px;

        @media (max-width: 767px) {
          margin-right: 15px;
          margin-left: 15px;
        }

        span {
          color: black;
          font-size: 20px;
          margin-bottom: 15px;
          margin-top: 0;

          &.label {
            display: block;
            color: rgba(0, 0, 0, 0.45);
            font-size: 15px;
            margin-bottom: 10px;
          }
        }
      }
      .tableItem {
        background-color: #ffffff;
        margin-top: 8px;
        border-radius: 5px;
        padding: 8px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        margin: 5px 15px;

        &.noPointer {
          cursor: initial;
        }

        &.create {
          background-color: $green;
          color: white;
          justify-content: center;

          .tableItemIcon {
            background-color: white;
            color: $green;
          }
        }

        .tableItemIcon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: $icon;
          line-height: 16px;
          font-size: 16px;
          width: 26px;
          height: 26px;
          border-radius: 13px;
          margin-right: 20px;
        }
      }
    }
    .pageTableContent {
      background: $lightgreey;
      border-radius: 15px;
      border: 1px solid #CFCFCF;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-self: stretch;
      margin-right: 30px;
      margin-left: 30px;
      max-width: 72vw;
      max-height: 75vh;

      @media (max-width: 767px) {
        margin-right: 15px;
        margin-left: 15px;
        overflow: auto;
        max-width: calc(100vw - 107px);
      }

      .chartBlock {
        background: #FFFFFF;
        border-radius: 40px;
        padding: 20px;
        margin-top: 20px;

        .chart {
          margin-top: 20px;
          width: 150px;
          min-height: 150px;
        }
      }

      .tableTitle {
        background: #FFFFFF;
        border-bottom: 1px solid #CFCFCF;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        font-weight: 500;
        font-size: 18px;
        padding: 10px;
      }

      .tableContent {
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        flex: 1;
        overflow: auto;
        font-size: 13px;

        .greenTable {
          flex: 1;
          display: flex;
          overflow: auto;
          margin-top: 15px;

          > table {
            border-radius: 0;

            > thead {
              background-color: $green;
              color: white;
              border-radius: 0;

              >tr > th {
                padding: 10px;
                border: solid 1px $lightgreen;
                font-size: 13px;

                &:first-child, &:last-child {
                  border-radius: 0;
                }
              }
            }

            > tbody {
              border: solid 1px white;

              > tr {
                &:last-child {
                  > td {
                    border-bottom: solid 1px white;
                  }
                }

                > td {
                  text-align: center;
                  vertical-align: center;
                  border: solid 1px $green;
                  cursor: initial;
                  font-size: 13px;

                  &.inputTd {
                    padding: 0;

                    > input {
                      border-radius: 0;
                      width: 100px;
                      font-size: 15px;
                    }
                  }

                  &:first-child {
                    border-left: solid 1px white;
                  }
                  &:last-child {
                    border-right: solid 1px white;
                  }
                }
              }
            }
          }
        }

        .tableItem {
          background-color: #ffffff;
          margin-top: 8px;
          border-radius: 5px;
          padding: 8px 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          &.noPointer {
            cursor: initial;
          }

          &.create {
            background-color: $green;
            color: white;
            justify-content: center;

            .tableItemIcon {
              background-color: white;
              color: $green;
            }
          }

          .tableItemIcon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $icon;
            line-height: 16px;
            font-size: 16px;
            width: 26px;
            height: 26px;
            border-radius: 13px;
            margin-right: 20px;
          }
        }
      }

      table {
        border-radius: 15px;
        border-collapse: collapse;

        thead {
          background: #FFFFFF;
          border-bottom: 1px solid #CFCFCF;
          border-radius: 15px;

          th {
            font-weight: 500;
            border: none;
            font-size: 18px;
            padding: 10px;
            border: 0px #ffffff;

            &:first-child {
              border-top-left-radius: 15px;
            }
            &:last-child {
              border-top-right-radius: 15px;
            }
          }
        }

        tbody {
          overflow: auto;

          tr {
            background: $lightgreey;
            border: 1px solid #CFCFCF;
            border-radius: 5px;
            cursor: pointer;

            td {
              background: #FFFFFF;
              font-weight: 500;
              font-size: 15px;
              padding: 10px;

              &.tableItem.create {
                background-color: $green;
                color: white;
                justify-content: center;
                text-align: center;

                .tableItemIcon {
                  background-color: white;
                  color: $green;
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  margin-right: 10px;
                }
              }

              &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }
              &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
          }
        }
      }
    }
  }

  .centerBlock {
    z-index: 2;
    display: block;
    padding: 60px;
    background: #FFFFFF;
    border-radius: 15px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;

    @media (max-width: 767px) {
      padding: 40px 20px;
      margin: 10px;
    }

    .centerBlock-title {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 38px;
      line-height: 45px;
      /* identical to box height */
      text-align: center;
      color: #000000;
      margin-top: 0;
      margin-bottom: 35px;
    }
  }

  .link {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    text-decoration-line: underline;
    color: #000000;

    &.light {
      font-weight: 300;
    }

    &:hover, &:active, &:visited {
      cursor: pointer;
      color: #000000;
    }
  }

  input, select {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    max-width: 300px;
    height: 50px;
    padding: 12px;
    font-size: 22px;
    font-family: 'Lato';

    &.fullWidth {
      max-width: 100%;
    }

    &.little {
      height: 30px;
      font-size: 14px;
      width: auto;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &.code {
      height: 70px;
      font-size: 36px;
      text-align: center;
    }
  }
  select {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    max-width: 300px;
    padding: 5px;
    font-size: 22px;
    font-family: 'Lato';

    &.little {
      height: 30px;
      font-size: 14px;
      width: auto;
    }
  }

  button {
    padding: 8px 10px;
    width: 100%;
    max-width: 300px;
    min-height: 40px;
    height: auto;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 22px;
    font-family: 'Raleway';
    margin-bottom: 5px;

    &.green {
      background-color: $green;
      border: 1px solid $icon;
      color: white;
    }

    &.sm {
      width: auto;
      font-size: 14px;
      padding: 4px;
    }
  }
}

.oldIcon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.financeTable {
  border: solid 2px $green;
  border-radius: 5px;

  td {
    padding: 10px;
    align-items: center;
    justify-content: center;

    border: solid 2px $green;

    .btn {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background: $green;
      color: white;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      > p {
        font-size: 14px;
        font-weight: 700;
        line-height: 0px;
        margin-bottom: 0;
      }
    }

    p {
      text-align: center;
    }
  }
}

.iconImage {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 15px;
  object-fit: contain;
}

.redText {
  color: #ff0000;
  font-weight: 700;
}
