@import "colors";

/* BLOCK for custom inputs */
.customDropdown {
  display: inline-flex;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 100%;
  position: relative;

  > div {
    display: none;
  }

  &.active {
    > div {
      display: block;
      position: absolute;
      top: 0;
      right: 42px;
      padding: 5px 0;
      width: 120px;
      max-height: 115px;
      overflow: auto;
      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      box-sizing: border-box;
      border-radius: 5px;

      > div {
        width: 120px;
        height: 35px;
        flex: none;
        order: 0;
        margin: 0px 0px;
        display: flex;
        align-items: center;
        text-align: center;

        span {
          width: 120px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.8);
        }

        &:hover {
          background: #E7E7E7;
        }
      }
    }
  }
}

.customCheckbox {
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
  margin-right: 10px;

  input, select {
    position: absolute;
    z-index: -1;
    opacity: 0;
    background: #FFFFFF;
    border: 2px solid $green;
    box-sizing: border-box;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }
  input+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  input+label::before {
    content: '';
    display: inline-block;
    background: #FFFFFF;
    border: 2px solid $green;
    box-sizing: border-box;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin-right: 15px;
  }
  input:checked+label::before {
    border-color: $green;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23105F1E' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
}
/* END BLOCK for custom inputs */

/* BLOCK for user Preview */
.userPreview {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* END BLOCK for user Preview */

/* BLOCK for header */
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-image: url("../images/header_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .userBlock {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 22px;
    background-color: white;
    cursor: pointer;

    .whoIs {
      font-size: 18px;
      font-family: 'Lato';
      margin-right: 18px;
      margin-left: 18px;

      @media (max-width: 767px) {
        margin-right: 9px;
        margin-left: 9px;
        font-size: 14px;
        text-overflow: ellipsis;
        max-width: 25vw;
      }
    }

    > svg {
      height: 22px;
      width: 22px;
      cursor: pointer;
    }

    .userFirst {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background-color: $green;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        color: white;
        font-size: 22px;
      }
      > svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
/* END BLOCK for header */

/* BLOCK for menu */
.menu {
  width: 75px;
  height: 100vh;
  background-color: white;
  border-right: 1px solid $border;

  .menu-item-list {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    max-height: calc(100vh - 100px);

    .menu-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 70px;
      width: 80%;
      font-size: 18px;
      font-family: 'Raleway';
      padding-left: 0;
      padding-right: 0;
      color: black;
      text-decoration: none;
      cursor: pointer;

      > span {
        display: none;
      }

      &.active {
        background-color: rgba(16, 95, 30, 0.05);
        border-right: 4px solid rgba(16, 95, 30, 0.9);
        color: $green;
      }

      > svg {
        width: 24px;
        height: 24px;
        margin: 0 auto;
      }
    }
  }

  .menu-name {
    display: none;
  }

  .menu-burger {
    width: 44px;
    height: 44px;
    border-radius: 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

    > svg {
      width: 22px;
      height: 22px;
    }
  }

  @media (min-width: 768px) {
    &.open {
      width: 20vw;
      max-width: 400px;

      .menu-name {
        display: block;
        width: 100%;
        max-width: 70%;
      }

      .menu-burger {
        width: 44px;
        height: 44px;
        border-radius: 32px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 5px;

        > svg {
          width: 22px;
          height: 22px;
        }
      }

      .menu-link {
        padding-left: 15px;
        padding-right: 15px;

        > span {
          display: inline-block;
        }

        > svg {
          width: 24px;
          height: 24px;
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
}

/* END BLOCK for menu */

/* BLOCK for sms code input */
.codeInput {
  position: relative;

  > input {
    //display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  > div {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 271px;
    height: 70px;

    background-color: $bg;
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: 6px;

    font-size: 36px;
    font-family: 'Lato';
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px;

    > span {
      &.placeholder {
        color: rgba(0, 0, 0, 0.1);
      }

      &.border {
        border-left: 1px solid $border;
        height: 50px;
        width: 0;
      }
    }
  }
}

/* END BLOCK for sms code input */

/* BLOCK for Styled Background */
.styledBG-container {
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: $bg;
  background: url("../images/login_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;

  .styledBG {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 0;

    .styledBG-greenBlock {
      width: 40vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        width: 90vw;
      }

      .styledBG-name {
        margin-left: 40px;
        margin-top: 40px;
        width: 260px;
      }

      .styledBG-tree {
        width: 30vw;
        height: auto;
        margin-left: 40px;
        @media (max-width: 767px) {
          width: 70vw;
        }
      }
    }
  }

  .styledBG-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* END BLOCK for Styled Background */

/* BLOCK for Spinner */
.loader {
  border: 4px solid $grey; /* Light grey */
  border-top: 4px solid $green; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* END BLOCK for Spinner */
